.product-banner {
    background-image: url(../../../public/assets/images/video_bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
}

.overlay {
    background-color: rgba(6, 42, 77, 0.9);
    z-index: 1;
    background-size: cover;
    padding: 110px 0;
}

.product-text {
    text-align: center;
    margin-bottom: 30px;
}

.product-text .product-heading {
    font-family: "Poppins", sans-serif;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 58px;
    line-height: 70px;
    margin-bottom: 20px;
}

.product-text .bread-crumb {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 15px;
}

.product-text .bread-crumb span {
    color: rgba(249, 154, 0, 1);
}

.product-gallery {
    background-image: url(./productimg/pro-bg.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    z-index: 0;
}

.product-tab-title {
    font-size: 36px;
    font-weight: 700;
    color: #062A4D;
}

.pearched-area p {
    color: rgba(116, 115, 115, 1);
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
}



.bar {
    height: 2px;
    width: 165px;
    background: #c4c4c4;
    margin: 20px auto 30px;
    position: relative;
    /* border-radius: 50%; */
}

.bar::before {
    content: '';
    position: absolute;
    left: 0;
    top: -3px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: #F99A00 none repeat scroll 0 0;
    animation-name: MOVE-BG;
}

@keyframes MOVE-BG {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(163px);
    }
}

.product-img {
    aspect-ratio: 1/1;
    vertical-align: middle;
    /* max-width: 150px; */
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.img-card {
    display: block;
    position: relative;
    cursor: pointer;
    margin: 18px 0px 0 0;
    /* background-color: rgba(9, 41, 73, 0.1); */
    padding: 25px 0 0 0;
    transition: .5s ease;
}

.buy-btn {
    text-align: center;
    background-color: #062A4D;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 0;
    /* margin-bottom: 10px; */
}

.price-detail {
    text-align: center;
}

.pro-name {
    color: #062A4D;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
}

.pro-price {
    display: flex;
    justify-content: center;
}



.price {
    font-size: 22px;
    font-weight: 18px;
    font-weight: 600;
    color: #F99A00;
}

.offer-price {
    font-size: 16px;
    font-weight: 18px;
    font-weight: 400;
    color: gray;
}

.offer {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #F99A00;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    padding: 5px 8px;
    border-radius: 50px;
}

.product-rating span {
    font-size: 20px !important;
}

.nav-tabs {
    padding: 20px 40px !important;
    box-shadow: 0 0 5px 2px rgba(149, 149, 149, 0.22);
    width: fit-content;
}



.product .nav-tabs {
    border-bottom: none !important;
    padding: 10px 30px;
}

.product-title {
    font-size: 36px !important;
    font-family: "Poppins", sans-serif !important;
    color: #062A4D !important;
    line-height: 43px !important;
    font-weight: 700 !important;
    margin-bottom: 15px;
}



.product-tabs .btn {
    background-color: #F99A00 !important;
    padding: 10px 15px !important;
    color: #687D95 !important;
    transition: 0.01s all;
    margin: 0 5px;
}

.nav-tabs .nav-link {
    border: none !important;
    transition: all !important;
}



.product-tabs .nav-link.active {
    color: #FFFFFF !important;
    background-color: #F99A00 !important;
    border: none !important;
    border-radius: 50px !important;
}

.product-tabs .nav-link:focus {
    border: none;
}

.product-tabs .nav-link:hover {
    border: none !important;
}

.single-product {
    padding: 50px 0px;
    background-color: rgba(245, 245, 245, 1);
}

.single-product img {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
    padding: 30px;
}


.slide-img {
    /* border: 1px solid #062A4D; */
    background-color: rgba(245, 245, 245, 1);
    width: 100px !important;
    height: 100px;
    padding: 10px;
}



.slide-img img {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
}

.product-rating ul {
    padding: 0;
    margin: 0;
    margin-right: 30px !important;
    display: inline-block;
}

.product-rating ul li {
    display: inline-block;
    font-size: 12px;
}

.product-rating ul li i {
    color: #F99A00;

}

.product-rating span {
    color: #747373;

}

.product-details-content>p {
    color: rgba(116, 115, 115, 1);
    margin-bottom: 45px;
    font-size: 14px;
    line-height: 28px;
}

.perched-info {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 40px;
    border-bottom: 1px dashed #d7d7d7;
    margin-bottom: 25px;
}

.cart-plus {
    width: 134px;
    position: relative;
    /* margin-right: 30px; */
}

.cart-plus-minus {
    position: relative;
}

.cart-plus-minus::before {
    content: "";
    position: absolute;
    left: 40px;
    height: 100%;
    width: 2px;
    top: 0;
    background: #F99A00 none repeat scroll 0 0;
}

.cart-plus-minus input {
    width: 100%;
    color: #F99A00;
    padding: 11px 40px;
    text-align: center;
    font-size: 16px;
    border-radius: 50px;
    border: 2px solid #F99A00;
}

.cart-plus-minus input:focus {
    outline: 1px solid #F99A00;
}


.cart-plus-minus::after {
    content: "";
    position: absolute;
    right: 40px;
    height: 100%;
    width: 2px;
    top: 0;
    background: #F99A00 none repeat scroll 0 0;
}

.dec.qtybutton {
    font-size: 23px;
}

.inc.qtybutton {
    right: 3px;
    left: auto;
}

.qtybutton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
    font-size: 20px;
    cursor: pointer;
    user-select: none;
    font-weight: normal;
    width: 35px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    color: #F99A00;
}

.perched-info .btn {
    border-radius: 50px;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1;
    margin-bottom: 0;
    background: #F99A00 none repeat scroll 0 0;
    padding: 16px 40px;
    border: 2px solid #F99A00;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: all 0.4s ease-in-out;
    vertical-align: middle;
    white-space: nowrap;
}

.perched-info .btn:hover {
    color: #F99A00;
    background: transparent;
    border: 2px solid #F99A00;
}

.product-info h5 {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 20px;
    color: #062a4d;
    margin-top: 0px;
    font-style: normal;
    font-weight: 700;
}

.product-info ul li {
    display: block;
    margin-bottom: 10px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    color: #747373;
}

.product-info ul li span {
    margin-right: 5px;
    color: #F99A00;
}

.product-desc-wrap .nav-tabs {
    box-shadow: none;
    border-bottom: none;
    padding: 0 !important;
}

.product-desc-wrap .nav-tabs .nav-item {
    margin-bottom: 0;
    margin-right: 50px;
    display: block;
}

.product-desc-wrap .nav-tabs .nav-link {
    border: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #062a4d;
}

.product-desc-wrap .nav-tabs .nav-link.active {
    color: #F99A00;
}

.desc-content {
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 0;
    color: #747373;
}

.product-review-wrap h5 {
    font-size: 13px;
    color: #545353;
    margin-bottom: 23px;
    font-weight: 600;
}

.single-review {
    display: flex;
    align-items: center;
}

.review-avatar {
    margin-right: 25px;
}

.review-avatar img {
    border: 4px solid #ebebeb;
}

.review-content {
    overflow: hidden;
}

.review-meta h6 {
    color: #393939;
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.review-meta h6 span {
    color: #F99A00;
}

.review-content p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    color: #747373;
}



.realted-product .product-card img {
    height: 350px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    aspect-ratio: 3/2;
    margin-bottom: 15px;

}

.product-card {
    position: relative;
}

.realted-product .product-card .buy-btn {
    cursor: pointer;
}

.realted-product .slick-prev {

    left: -45px;
    background-color: #FFFFFF;
}

.realted-product .slick-next {

    background-color: transparent;
}

.realted-product .slick-prev:before {
    content: '\F22D';
    font-family: 'bootstrap-icons';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: #F99A00;
}

.realted-product .slick-next:before {
    content: '\F231';
    font-family: 'bootstrap-icons';
    font-size: 40px;
    line-height: 1;
    opacity: 1;
    color: #F99A00;
}

.realted-product .slick-next {
    right: -25px !important;
}





@media screen and (max-width:576px) {

    .realted-product .slick-next {
        right: 0px !important;
    }

    .realted-product .slide-img {
        background-color: rgba(245, 245, 245, 1);
        width: 80px !important;
        height: 80px;
        padding: 10px;
    }

    .nav-tabs {
        padding: 15px 25px !important;
    }

    .nav-item button {
        padding: 10px;
    }

    .realted-product .slick-prev::before {
        content: '';
        font-size: 0px;

        line-height: 0;
    }



    .realted-product .slick-next::before {
        content: '';
        color: transparent;
        font-size: 0px;
        line-height: 0;
        background: none;
    }



    .realted-product .slick-next:before {
        content: none !important;
    }
}

/* rivew rate */
h6.rating-inner {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 600;
}
span.optin{
    font-weight:400;
}
.review-inner {
    display: flex;
    align-items: center;
}


textarea {
    width: 500px;
    height: 180px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    padding: 5px 8px;
    transition: background-color 0.2s ease 0s;
  }
  
  
  textarea:focus {
    outline-width: 0;
  }
  textarea#comment_text {
    margin-top: 10px;
}

a.btn-submit {
    color: #FFFFFF;
    background-color: black;
    text-decoration: none;
    padding: 10px 20px;
}
.bttnsubmit {
    margin-top: 30px;
}
/* FormWizard.css */

.form-wizard-container {
  display: flex;
  background-color: #f8f9fa;
}

.form-container {
  background-color: white;
  border-radius: 8px;
  padding: 40px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  /* height: 400px; */
  max-width: 1600px;
}




.tracking-info {
  margin-top: 1.5rem; /* Add some space above */
}

.estimated-delivery {
  font-size: 1.125rem; /* Equivalent to text-lg */
  font-weight: 600;    /* Equivalent to font-semibold */
}

.edd-highlight {
  font-weight: 800;    /* Equivalent to font-extrabold */
}


.form-title {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.track-order-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.awb-label {
  font-size: 1rem;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-control {
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
}

.btn {
  padding: 10px 20px;
  background-color: #343a40;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
}

.btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tracking-result {
  margin-top: 20px;
}

.timeline-container {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.timeline-item {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-left: 2px solid #343a40;
  padding-left: 15px;
  position: relative;
}

.timeline-item::before {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #343a40;
  border-radius: 50%;
  position: absolute;
  left: -7px;
  top: 8px;
}

.timeline-date {
  font-size: 0.875rem;
  color: #666;
}

.timeline-content {
  margin-top: 5px;
}

.timeline-content p {
  margin: 3px 0;
  font-size: 0.875rem;
}

.error {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.blog-title {
    background-image: url(../../../public/assets/images/Mainbg1.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 0;
}

.overlay {
    background-color: rgba(6, 42, 77, 0.4);
    z-index: 1;
    background-size: cover;
    padding: 110px 0;
}

.blog-text {
    text-align: center;
    margin-bottom: 30px;
}

.blog-text .blog-heading {
    font-family: "Poppins", sans-serif;
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 58px;
    line-height: 70px;
    margin-bottom: 20px;
}

.blog-text .bread-crumb a {
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 15px;
}

.blog-text .bread-crumb span {
    color: rgba(249, 154, 0, 1);
}

@media screen and (max-width: 992px) {
    .blog-text .blog-heading {
        font-size: 50px;
        line-height: 70px;
        margin-bottom: 20px;
    }

    .blog-text .bread-crumb {
        font-size: 15px;
        line-height: 27px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 768px) {
    .blog-text .blog-heading {
        font-size: 45px;
        line-height: 70px;
        margin-bottom: 15px;
    }

    .blog-text .bread-crumb {
        font-size: 15px;
        line-height: 27px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .blog-text .blog-heading {
        font-size: 45px;
        line-height: 70px;
        margin-bottom: 15px;
    }

    .blog-text .bread-crumb {
        font-size: 15px;
        line-height: 27px;
        margin-bottom: 10px;
    }
}
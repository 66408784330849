.advideo-area .slick-arrow.slick-next,
.advideo-area .slick-arrow.slick-prev,
.review-video .slick-arrow.slick-next,
.review-video .slick-arrow.slick-prev {
    background-color: #F99A00;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.8;
    height: 30px;
    width: 30px;
}

.review-video .slick-arrow.slick-prev{
    left: 10px !important;
    z-index: 99;
}

.review-video .slick-arrow.slick-next{
    right: 10px !important;
    z-index: 99;
}

.advideo-area .slick-arrow.slick-next {
    position: absolute;
    top: 300px;
    right: -75px !important;
}

.advideo-area .slick-arrow.slick-prev {
    position: absolute;
    top: 300px;
    left: -75px;
}

.advideo-area .slick-arrow.slick-next:hover,
.advideo-area .slick-arrow.slick-prev:hover {
    background-color: #F99A00;
    opacity: 1;
}


.advideo-area .slick-prev::before,
.slick-next::before,
.review-video .slick-prev::before,
.slick-next::before {
    display: none;

}

.advideo-area .arrows,
.review-video .arrows {
    height: 20px;
    width: 20px;
    margin: 1px;
}

.advideo-area img {
    z-index: -99;
}

.advideo-area .video-play {
    position: absolute;
    top: 260px;
    left: 0;
}

.advideo-area .video-play .popup-video {
    cursor: pointer;
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    color: #fff;
    margin: 0 auto;
    position: relative;
    font-size: 18px;
    background: #F99A00 none repeat scroll 0 0;

}

.advideo-area .video-play .popup-video::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: background-color 0.25s ease-out;
    background-color: #fff;
    animation: hoop-animation 2s 0s both ease-out infinite;
    animation-play-state: running;
    z-index: -1;
}

.advideo-area .video-play .popup-video::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: background-color 0.25s ease-out;
    background-color: #fff;
    animation: hoop-animation 2s 1s ease-out infinite;
    animation-play-state: running;
    z-index: -1;
}

.fa-play:before {
    color: #fff;
}

.advideo-area .modal-content {
    display: flex;
    flex-direction: row !important;
}

.advideo-area .modal-header .btn-close {
    color: #fff;
    position: absolute;
    top: -25px;
    right: 0;
    padding: 0 !important;
    margin: 0 !important;
}

.review-video span {
    cursor: pointer;
}

@keyframes hoop-animation {
    0% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(0.5);
    }

    100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
}


@media screen and (max-width:1400px) {
    .advideo-area .slick-arrow.slick-next {
        position: absolute;
        top: 260px;
        right: -75px !important;
    }

    .advideo-area .slick-arrow.slick-prev {
        position: absolute;
        top: 260px;
        left: -75px;
    }

    .advideo-area .video-play {
        position: absolute;
        top: 225px;
        left: 0;
    }

}

@media screen and (max-width:1200px) {
    .advideo-area .slick-arrow.slick-next {
        position: absolute;
        top: 210px;
        right: -75px !important;
    }

    .advideo-area .slick-arrow.slick-prev {
        position: absolute;
        top: 210px;
        left: -75px;
    }

    .advideo-area .video-play {
        position: absolute;
        top: 180px;
        left: 0;
    }

}

@media screen and (max-width:992px) {
    .advideo-area .slick-arrow.slick-next {
        position: absolute;
        top: 210px;
        right: -50px !important;
    }

    .advideo-area .slick-arrow.slick-prev {
        position: absolute;
        top: 210px;
        left: -50px;
    }

    .advideo-area .video-play {
        position: absolute;
        top: 180px;
        left: 0;
    }
}

@media screen and (max-width:768px) {
    .advideo-area .slick-arrow.slick-next {
        position: absolute;
        top: 160px;
        right: -30px !important;
    }

    .advideo-area .slick-arrow.slick-prev {
        position: absolute;
        top: 160px;
        left: -30px;
    }

    .advideo-area .video-play {
        position: absolute;
        top: 135px;
        left: 0;
    }
}

@media screen and (max-width:576px) {
    .advideo-area .slick-arrow.slick-next {
        position: absolute;
        top: 110px;
        right: 10px !important;
    }

    .advideo-area .slick-arrow.slick-prev {
        z-index: 99;
        position: absolute;
        top: 110px;
        left: 10px;
    }

    .advideo-area .video-play {
        position: absolute;
        top: 80px;
        left: 0;
    }
}

@media screen and (max-width:412px) {
    .advideo-area .slick-arrow.slick-next {
        position: absolute;
        top: 95px;
        right: 10px !important;
    }

    .advideo-area .slick-arrow.slick-prev {
        z-index: 99;
        position: absolute;
        top: 95px;
        left: 10px;
    }

    .advideo-area .video-play {
        position: absolute;
        top: 65px;
        left: 0;
    }
}
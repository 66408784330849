/* .login-left {} */

.login-right {
    background-color: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 35px !important;
    box-shadow: 0 0 3px 1px #b8b8b8;
}

.login-header h5 {
    color: rgba(6, 42, 77, 1) !important;
}

.err-text {
    font-size: 12px;
}

.login-right label {
    font-size: 12px;
    color: #b8b8b8 !important;
}

.register-btn,
.login-btn {
    font-size: 15px !important;
    background-color: #F99A00 !important;
    border: 2px solid #F99A00 !important;
    border-radius: 50px !important;
    color: #ffffff !important;
}

.register-btn:hover,
.login-btn:hover {
    color: #F99A00 !important;
    background-color: transparent !important;

}

.f-pass {
    font-size: 12px !important;
}

.dont-have {
    font-size: 14px;
    color: rgba(6, 42, 77, 1) !important;
}

@media screen and (max-width:768px) {

    .login-left {
        display: none;
    }
}
.productpage {
  padding-top: 70px;
  padding-bottom: 70px;
}

.sec-title .sec-head-text {
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
  color: rgba(6, 42, 77, 1);
  margin-bottom: 15px;
}

.sec-title .sec-sub-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  color: rgba(116, 115, 115, 1);
  margin-bottom: 15px;
}

.certi-text p {
  font-size: 14px;
  line-height: 28px;
  color: rgba(116, 115, 115, 1);
}

.certi-content .certi-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: rgba(142, 142, 142, 1);
  margin-bottom: 15px;
}

.certi-content .certi-text {
  font-size: 37px;
  line-height: 44px;
  font-weight: 600;
  color: rgba(17, 17, 17, 1);
  margin-bottom: 15px;
}

.certi-content .bot-line {
  height: 2px;
  width: 50%;
  background: #F99A00;
  margin: 10px auto 20px auto;
}

.certi-content .certi-sub-text {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: rgba(142, 142, 142, 1);
  margin-bottom: 15px;
}

.right-col {
  padding: 50px;
  order: 0;
}

.left-col{
  order: 1;
}

.slick-next {
  right: -1px !important;
}

@media screen and (max-width:992px) {

  .left-col {
    order: 0
  }

  .right-col {
    order: 1;
  }

  .right-col {
    padding: 35px;
  }
}

@media screen and (max-width:768px) {

  .left-col {
    order: 0
  }

  .right-col {
    order: 1;
  }


  .right-col {
    padding: 25px;
  }
}

@media screen and (max-width:576px) {

  .left-col {
    order: 0
  }

  .right-col {
    order: 1;
  }

  .right-col {
    padding: 15px;
  }
}
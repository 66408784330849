.step-progress-bar {
  display: flex;
  justify-content: space-around !important; 
  margin-bottom: 1rem;
}

.step {
  flex: 1;
  padding: 0.5rem;
  background-color: #e9ecef;
  text-align: center;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
}

.step.active {
  background-color: #F99A00;
  color: white;
}

.custom-progress-bar {
  height: 6px !important;
  background-color: #e9ecef;

}

.question-title {
  color: #F99A00;
}

.question-card {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;

}

.option-check {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 50px;
  background-color: #f9f9f9;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option-check:hover {
  background-color: #f1f1f1;
}

.option-check input[type="checkbox"] {
  margin-right: 10px;
}

.option-check.checked {
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.result-image {
  max-width: 250px;
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.result-text {
  text-align: left;
}

.nxt-btn,
.prev-btn {
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  background: #062A4D none repeat scroll 0 0;
  padding: 10px 20px;
  border: 2px solid #062A4D;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.2s ease-in-out;
  vertical-align: middle;
  white-space: nowrap;

}

.nxt-btn:hover,
.prev-btn:hover {
  color: #062A4D;
  background: transparent;
  border: 2px solid #062A4D;
}

.buy-now-btn {
  border-radius: 50px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  margin-bottom: 0;
  background: #F99A00 none repeat scroll 0 0;
  padding: 16px 40px;
  border: 2px solid #F99A00;
  text-align: center;
  text-transform: uppercase;
  touch-action: manipulation;
  transition: all 0.4s ease-in-out;
  vertical-align: middle;
  white-space: nowrap;
}

.buy-now-btn:hover {
  color: #F99A00;
  background: transparent;
  border: 2px solid #F99A00;
}
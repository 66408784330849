.offcanvas-header {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.offcanvas-body {
    padding: 0 !important;

}

.cart-empty-title h5 {
    color: rgba(6, 42, 77, 1);
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
}

.mini-cart-item {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    margin-bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, 1);
}

.mini-cart-item:first-child {
    border-top: 0;
}

.mini-cart-img {
    float: left;
    background-color: rgba(245, 245, 245, 1);
    border-radius: 8px;
    padding: 5px;
    width: 70px;
    height: 70px;
    margin-right: 15px;
    position: relative;
}

.mini-cart-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.mini-cart-item-delete {
    position: absolute;
    left: -8px;
    top: -8px;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    display: block;
    line-height: 20px;
    background-color: #fff;
    text-align: center;
    font-size: 20px;
    box-shadow: 0 0 5px 2px rgba(149, 149, 149, 0.22);
    cursor: pointer;
    transition: all .3s ease 0s;
}

.mini-cart-item-delete:hover {
    background-color: rgba(6, 42, 77, 1);
}

.mini-cart-item-delete:hover i {
    color: #FFFFFF !important;
}

.mini-cart-info h6 {
    color: rgba(6, 42, 77, 1);
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 700;
}

.mini-cart-quantity {
    color: rgba(90, 90, 90, 1);
    font-weight: 400;
    font-size: 16px;
    line-height: 1.8;
}

.mini-cart-footer {
    margin-top: 25px;
}

.mini-cart-sub-total {
    border-top: 1px solid rgba(0, 0, 0, 1);
    border-bottom: 1px solid rgba(0, 0, 0, 1);
    padding: 20px 0;
}

.mini-cart-sub-total h5 {
    color: rgba(6, 42, 77, 1);
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.shopping-cart__total {
    color: rgba(6, 42, 77, 1);
}

.view-btn,
.check-btn {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    width: auto;
    padding: 10px 20px;
    background-color: rgba(6, 42, 77, 1);
    color: #FFFFFF;
    border: 2px solid rgba(6, 42, 77, 1);
    border-radius: 8px;
}

.view-btn:hover,
.check-btn:hover {
    background-color: transparent;
    color: rgba(6, 42, 77, 1);
}
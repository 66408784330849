/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'); */


.accountsettings {
    padding: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;

}

.accountsettings h1 {
    padding: 10px 0px;
    font-size: 25px;
    font-weight: 700;
    color: rgba(6, 42, 77, 1);
}

.mainhead1 {
    padding: 10px 0px;
    font-size: 25px;
    font-weight: 700;
    color: rgba(6, 42, 77, 1);
}

.accountsettings .form .form-group .form-label {
    font-size: 15px;
    font-weight: 500;
    color: rgba(6, 42, 77, 1);
}

.mainbutton1 {
    background-color: #F99A00;
    border: 1px solid #F99A00;
    padding: 10px 10px;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 600;
    color: #FFFFFF;
}

.mainbutton1:hover {
    background-color: transparent;
    color: #F99A00;
}

@media screen and (max-width: 768px) {
    .mainhead1 {
        font-size: 20px;
    }
}


@media (max-width: 800px) {
    /* .accountsettings .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
    } */

    /* .accountsettings .form .form-group {
        display: flex;
        flex-direction: column;
        width: 97%;
        margin: 10px;
    } */

    .accountsettings h1 {
        font-size: 20px;
    }

    .accountsettings .form .form-group label {
        font-size: 16px;
        font-weight: 300;

    }
}

@media screen and (max-width:576px) {
    .accountsettings {
        padding: 15px 20px;

    }
}
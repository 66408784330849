.cart .sec-title,
.wishlist .sec-title {
    background: #ebebeb;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 80px;
}

.cart .row h1,
.wishlist .row h1 {
    color: rgba(6, 42, 77, 1);
}

.cart-table tr {
    border-radius: 15px;
    border: 1px solid rgba(90, 90, 90, 0.3);
    border-bottom: 1px solid rgba(90, 90, 90, 0.3);
    border-top: 1px solid rgba(90, 90, 90, 0.3);
    overflow: hidden;
}

.cart-table td {
    border: none;
    font-size: 15px;
    color: rgba(6, 42, 77, 1) !important;
    font-weight: 700;
    vertical-align: middle;
    text-align: center;
}

.cart-table table tbody tr td {
    /* border-right: none; */
    padding: 10px;
}

.cart-table table tbody tr td.prod-thumbnail {
    width: 130px;
}

.thumb {
    max-width: 60px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cart-table table tbody tr td.prod-title {
    width: 200px;
}

.prod-title h6 {
    color: rgba(6, 42, 77, 1);
}

.cart-table table tbody tr td.prod-price {
    width: 130px;
}

.prod-price span {
    color: rgba(6, 42, 77, 1);
}

.cart-table table tbody tr td.prod-quantity {
    width: 180px;
}

.cart-pls-min {
    border-radius: 5px;
    border: 1px solid rgba(90, 90, 90, 0.3);
    margin: 0 auto;
    cursor: pointer;
    height: 61px;
    line-height: 56px;
    width: 140px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.qtbtn {
    height: 100%;
    width: 30%;
}

.min-button {
    border-right: 1px solid rgba(90, 90, 90, 0.3);
    float: left;
}



.pls-min-box {
    background: transparent none repeat scroll 0 0;
    box-shadow: none;
    border: none;
    height: 100%;
    margin-bottom: 0;
    padding: 0;
    color: rgba(6, 42, 77, 1);
    text-align: center;
    width: 40%;
    font-weight: 500;
}

.pls-min-box:focus {
    outline: none;
}


.pls-button {
    border-left: 1px solid rgba(90, 90, 90, 0.3);
    float: right;
}

.cart-table table tbody tr td.prod-subtotal {
    width: 120px;
}

.cart-table table tbody tr td.prod-remove {
    width: 150px;
}

.prod-remove i {
    cursor: pointer;
    color: rgba(6, 42, 77, 1);
    font-weight: 900;
    font-size: 30px;
}

.table-responsive {
    display: block;
    width: 100% !important;
}

.cont-btn,
.clr-btn {
    width: 250px;
    padding: 15px 30px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #F99A00;
    border: 2px solid #F99A00;
    transition: all 0.4s;
}

.cont-btn {
    margin-right: 20px;
    margin-bottom: 15px;
}

.cont-btn:hover,
.clr-btn:hover {
    background-color: #FFFFFF;
    color: #F99A00;
}

.cart-coupan h3,
.cart-total h3 {
    font-size: 22px;
    color: rgba(6, 42, 77, 1);
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 15px;
}

.cart-coupan textarea {
    border-radius: 15px !important;
    padding: 20px;
    border: 1px solid rgba(90, 90, 90, 0.3) !important;
    width: 100%;
}

.cart-total table td,
.cart-total table th {
    padding: 15px;
    color: rgba(6, 42, 77, 1);

}

.cart-total .order-total {
    background-color: #ebebeb;
}

.proceed-to-checkout {
    margin-top: 25px;
}

.proceed-to-checkout .checkbtn {
    line-height: 16px;
    width: 100%;
    padding: 15px 30px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #F99A00;
    border: 2px solid #F99A00;
    transition: all 0.4s;
}

.proceed-to-checkout .checkbtn:hover {
    background-color: #FFFFFF;
    color: #F99A00;
}

@media screen and (max-width: 767px) {

    .cart-table table,
    .cart-table thead,
    .cart-table tbody,
    .cart-table th,
    .cart-table td,
    .cart-table tr {
        display: block;
    }

    .cart-table tr {
        margin-bottom: 15px;
    }


    .cart-table table tbody tr td.prod-thumbnail {
        width: auto;
    }

    .cart-table table tbody tr td.prod-title {
        width: auto;
    }

    .cart-table table tbody tr td.prod-price {
        width: auto;
    }

    .cart-table table tbody tr td.prod-quantity {
        width: auto;
    }

    .cart-table table tbody tr td.prod-subtotal {
        width: auto;
    }

    .cart-table table tbody tr td.prod-remove {
        width: auto;
    }

    .cont-btn {
        margin-bottom: 20px;
        margin-right: 0;
    }
}